.hero-container {
    height: 100vh;
    height: 100svh;
    width: 100%;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    min-height: 900px;
}
.hero-bg-image {
    background: url('../assets/bg-full.jpg');
    height: 1080px;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
}
.hero-bg-dark-layer {
    height: 1080px;
    width: 100%;
    background-color: #000;
    opacity: .1;
}
.hero-bg-layer-1 {
    background: url('../assets/bg-left-right-layer.webp') no-repeat center center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: -750px; 
    z-index: 3;
}
.hero-bg-layer-2 {
    background: url('../assets/bg-center-layer.webp') no-repeat center center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: -1150px; 
    z-index: 2;
}
.hero-bg-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 60vh;
    min-height: 450px;
    z-index: 111;
}
.hero-bg-text h1 {
    margin: 0;
    font-size: calc(3rem + (53 - 16) * ((100vw - 320px) / (1600 - 320)));
    text-transform: uppercase;
    opacity: 0;
}
.hero-bg-text p {
    font-size: calc(1.5rem + (31 - 12) * ((100vw - 320px) / (1600 - 320)));
    margin: 0;
    opacity: 0;
}
@media(max-width:612px) {
    .hero-container {
        min-height: 600px;
    }
}

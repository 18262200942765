@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
:root {
    --lightblue: #A2B2EE;
    --lightgrey: #2E3239;
    --darkblue: #5F7ADB;
    --darkgrey: #26292B;
}
@media(prefers-reduced-motion: no-preference) {
    :has(:target) {
        scroll-behavior: smooth;
        scroll-padding-top: 2rem;
    }
}
* {
    box-sizing: border-box;
}
body {
    margin: 0;
    padding: 0;
    background: var(--darkgrey);
    color:#fff;
    font-family:'Poppins', sans-serif;
}